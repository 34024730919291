const tractorConstants = {
  CREATE_TRACTOR_REQUEST: 'TRACTORS_CREATE_TRACTOR_REQUEST',
  CREATE_TRACTOR_SUCCESS: 'TRACTORS_CREATE_TRACTOR_SUCCESS',
  CREATE_TRACTOR_FAILURE: 'TRACTORS_CREATE_TRACTOR_FAILURE',

  UPDATE_TRACTOR_REQUEST: 'TRACTORS_UPDATE_TRACTOR_REQUEST',
  UPDATE_TRACTOR_SUCCESS: 'TRACTORS_UPDATE_TRACTOR_SUCCESS',
  UPDATE_TRACTOR_FAILURE: 'TRACTORS_UPDATE_TRACTOR_FAILURE',
};

export default tractorConstants;
