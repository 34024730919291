const uiConstants = {
  CHANGE_PAGE_TITLE: 'PAGE_TITLE',
  HANDLE_MODAL: 'HANDLE_MODAL',

  IMPORT_DATA_REQUEST: 'IMPORT_DATA_REQUEST',
  IMPORT_DATA_SUCCESS: 'IMPORT_DATA_SUCCESS',
  IMPORT_DATA_FAILURE: 'IMPORT_DATA_FAILURE',
};

export default uiConstants;
