const trailerTypeConstants = {
  CREATE_TRAILER_TYPE_REQUEST: 'TRAILER_TYPES_CREATE_TRAILER_TYPE_REQUEST',
  CREATE_TRAILER_TYPE_SUCCESS: 'TRAILER_TYPES_CREATE_TRAILER_TYPE_SUCCESS',
  CREATE_TRAILER_TYPE_FAILURE: 'TRAILER_TYPES_CREATE_TRAILER_TYPE_FAILURE',

  UPDATE_TRAILER_TYPE_REQUEST: 'TRAILER_TYPES_UPDATE_TRAILER_TYPE_REQUEST',
  UPDATE_TRAILER_TYPE_SUCCESS: 'TRAILER_TYPES_UPDATE_TRAILER_TYPE_SUCCESS',
  UPDATE_TRAILER_TYPE_FAILURE: 'TRAILER_TYPES_UPDATE_TRAILER_TYPE_FAILURE',
};

export default trailerTypeConstants;
