import tractorConstants from '../constants/tractorConstants';

const initialState = {
  creatingNewTractor: false,
  tractorCreated: false,
  updatingNewTractor: false,
  tractorUpdated: false,
};

export default function tractor(state = initialState, action) {
  switch (action.type) {
    case tractorConstants.CREATE_TRACTOR_REQUEST:
      return {
        ...state,
        creatingNewTractor: true,
        tractorCreated: false,
      };
    case tractorConstants.CREATE_TRACTOR_SUCCESS:
      return {
        ...state,
        creatingNewTractor: false,
        tractorCreated: true,
      };
    case tractorConstants.CREATE_TRACTOR_FAILURE:
      return {
        ...state,
        creatingNewTractor: false,
        tractorCreated: false,
      };
    case tractorConstants.UPDATE_TRACTOR_REQUEST:
      return {
        ...state,
        updatingNewTractor: true,
        tractorUpdated: false,
      };
    case tractorConstants.UPDATE_TRACTOR_SUCCESS:
      return {
        ...state,
        updatingNewTractor: false,
        tractorUpdated: true,
      };
    case tractorConstants.UPDATE_TRACTOR_FAILURE:
      return {
        ...state,
        updatingNewTractor: false,
        tractorUpdated: false,
      };
    case tractorConstants.UNLOAD_TRACTOR:
      return {};
    default:
      return state;
  }
}
