const driverConstants = {
  CREATE_DRIVER_REQUEST: 'DRIVERS_CREATE_DRIVER_REQUEST',
  CREATE_DRIVER_SUCCESS: 'DRIVERS_CREATE_DRIVER_SUCCESS',
  CREATE_DRIVER_FAILURE: 'DRIVERS_CREATE_DRIVER_FAILURE',

  UPDATE_DRIVER_REQUEST: 'DRIVERS_UPDATE_DRIVER_REQUEST',
  UPDATE_DRIVER_SUCCESS: 'DRIVERS_UPDATE_DRIVER_SUCCESS',
  UPDATE_DRIVER_FAILURE: 'DRIVERS_UPDATE_DRIVER_FAILURE',
};

export default driverConstants;
