import userConstants from '../constants/userConstants';

const initialState = {
  creatingNewUser: false,
  userCreated: false,
  updatingUser: false,
  userUpdated: false,
  requestNewPasswordRequest: false,
  newPasswordRequested: false,
  checkingToken: false,
  tokenChecked: false,
  tokenData: {
    valid: false,
    record: { token: '' },
  },
  creatingNewPassword: false,
  newPasswordCreated: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return {
        requesting: true,
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        data: action.user,
      };
    case userConstants.GET_USER_FAILURE:
      return {};
    case userConstants.CREATE_USER_REQUEST:
      return {
        ...state,
        creatingNewUser: true,
        userCreated: false,
      };
    case userConstants.CREATE_USER_SUCCESS:
      return {
        ...state,
        creatingNewUser: false,
        userCreated: true,
      };
    case userConstants.CREATE_USER_FAILURE:
      return {
        ...state,
        creatingNewUser: false,
        userCreated: false,
      };
    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        updatingUser: true,
        userUpdated: false,
      };
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updatingUser: false,
        userUpdated: true,
      };
    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        updatingUser: false,
        userUpdated: false,
      };
    case userConstants.REQUEST_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        requestNewPasswordRequest: true,
        newPasswordRequested: false,
      };
    case userConstants.REQUEST_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        requestNewPasswordRequest: false,
        newPasswordRequested: true,
      };
    case userConstants.REQUEST_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        requestNewPasswordRequest: false,
        newPasswordRequested: false,
      };
    case userConstants.CHECK_PASSWORD_TOKEN_REQUEST:
      return {
        ...state,
        checkingToken: true,
        tokenChecked: false,
      };
    case userConstants.CHECK_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        checkingToken: false,
        tokenChecked: true,
        tokenData: action.token,
      };
    case userConstants.CHECK_PASSWORD_TOKEN_FAILURE:
      return {
        ...state,
        checkingToken: false,
        tokenChecked: false,
      };
    case userConstants.CREATE_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        creatingNewPassword: true,
        newPasswordCreated: false,
      };
    case userConstants.CREATE_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        creatingNewPassword: false,
        newPasswordCreated: true,
      };
    case userConstants.CREATE_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        creatingNewPassword: false,
        newPasswordCreated: false,
      };
    case userConstants.UNLOAD_USER:
      return {};
    default:
      return state;
  }
}
