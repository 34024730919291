const userConstants = {

  GET_USER_REQUEST: 'USERS_GET_USER_REQUEST',
  GET_USER_SUCCESS: 'USERS_GET_USER_SUCCESS',
  GET_USER_FAILURE: 'USERS_GET_USER_FAILURE',

  CREATE_USER_REQUEST: 'USERS_CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'USERS_CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'USERS_CREATE_USER_FAILURE',

  UPDATE_USER_REQUEST: 'USERS_UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'USERS_UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'USERS_UPDATE_USER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  REQUEST_NEW_PASSWORD_REQUEST: 'USERS_REQUEST_NEW_PASSWORD_REQUEST',
  REQUEST_NEW_PASSWORD_SUCCESS: 'USERS_REQUEST_NEW_PASSWORD_SUCCESS',
  REQUEST_NEW_PASSWORD_FAILURE: 'USERS_REQUEST_NEW_PASSWORD_FAILURE',

  CHECK_PASSWORD_TOKEN_REQUEST: 'USERS_CHECK_PASSWORD_TOKEN_REQUEST',
  CHECK_PASSWORD_TOKEN_SUCCESS: 'USERS_REQUEST_CHECK_PASSWORD_TOKEN_SUCCESS',
  CHECK_PASSWORD_TOKEN_FAILURE: 'USERS_CHECK_PASSWORD_TOKEN_FAILURE',

  CREATE_NEW_PASSWORD_REQUEST: 'USERS_CREATE_NEW_PASSWORD_REQUEST',
  CREATE_NEW_PASSWORD_SUCCESS: 'USERS_CREATE_NEW_PASSWORD_SUCCESS',
  CREATE_NEW_PASSWORD_FAILURE: 'USERS_CREATE_NEW_PASSWORD_FAILURE',

  LOGOUT: 'USERS_LOGOUT',
};

export default userConstants;
