/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ContentLoader from 'react-content-loader';

const ProfileCard = (props) => (
  <ContentLoader
    speed={1}
    width={1200}
    height={400}
    viewBox="0 0 1200 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="522" y="53" rx="0" ry="0" width="2" height="300" />
    <rect x="736" y="55" rx="0" ry="0" width="2" height="300" />
    <rect x="521" y="53" rx="0" ry="0" width="216" height="2" />
    <rect x="521" y="353" rx="0" ry="0" width="216" height="2" />
    <circle cx="627" cy="147" r="44" />
    <rect x="524" y="53" rx="0" ry="0" width="216" height="41" />
    <rect x="548" y="207" rx="0" ry="0" width="160" height="9" />
    <rect x="582" y="236" rx="0" ry="0" width="92" height="9" />
    <rect x="556" y="324" rx="0" ry="0" width="146" height="51" />
  </ContentLoader>
);

export default ProfileCard;
