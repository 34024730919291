/* eslint-disable no-console */
import Cookies from 'js-cookie';
import userConstants from '../constants/userConstants';
import userService from '../services/userServices';
import alertActions from './alertActions';
import services from '../services/services';

function login(email, password, remember) {
  function loginRequest() { return { type: userConstants.LOGIN_REQUEST }; }
  function loginSuccess(auth) { return { type: userConstants.LOGIN_SUCCESS, auth }; }
  function loginFailure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }

  return (dispatch) => {
    dispatch(loginRequest({ email }));
    return userService.login(email, password)
      .then(
        (auth) => {
          localStorage.setItem('user', JSON.stringify(auth.data.user));
          const expiring = new Date();
          expiring.setMinutes(60 * 5 + 5);
          Cookies.set('auth', auth.data.token, { expires: remember ? null : expiring }, { secure: true }, { sameSite: 'strict' });
          dispatch(loginSuccess(auth));
        },
        (error) => {
          dispatch(loginFailure(error.toString()));
          dispatch(alertActions.error('Impossible de vous connecter, verifiez vos identifiants.'));
        },
      )
      .catch((error) => {
        console.error(error);
      });
  };
}

function requestNewPassword(email) {
  function requestNewPasswordRequest() {
    return { type: userConstants.REQUEST_NEW_PASSWORD_REQUEST };
  }
  function requestNewPasswordSuccess(token) {
    return { type: userConstants.REQUEST_NEW_PASSWORD_SUCCESS, token };
  }
  function requestNewPasswordFailure(error) {
    return { type: userConstants.REQUEST_NEW_PASSWORD_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(requestNewPasswordRequest({ email }));
    return userService.requestNewPassword(email)
      .then(
        (token) => {
          dispatch(requestNewPasswordSuccess(token));
        },
        (error) => {
          dispatch(requestNewPasswordFailure(error.toString()));
        },
      )
      .catch((error) => {
        console.error(error);
      });
  };
}

function checkPasswordToken(email, tokenCheck) {
  function checkPasswordTokenRequest() {
    return { type: userConstants.CHECK_PASSWORD_TOKEN_REQUEST };
  }
  function checkPasswordTokenSuccess(token) {
    return { type: userConstants.CHECK_PASSWORD_TOKEN_SUCCESS, token };
  }
  function checkPasswordTokenFailure(error) {
    return { type: userConstants.CHECK_PASSWORD_TOKEN_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(checkPasswordTokenRequest(email));
    return userService.checkPasswordToken(email, tokenCheck)
      .then(
        (token) => {
          dispatch(checkPasswordTokenSuccess(token.data));
        },
        (error) => {
          dispatch(checkPasswordTokenFailure(error.toString()));
        },
      )
      .catch((error) => {
        console.error(error);
      });
  };
}

function createNewPassword(body) {
  function createNewPasswordRequest() {
    return { type: userConstants.CREATE_NEW_PASSWORD_REQUEST };
  }
  function createNewPasswordSuccess(token) {
    return { type: userConstants.CREATE_NEW_PASSWORD_SUCCESS, token };
  }
  function createNewPasswordFailure(error) {
    return { type: userConstants.CREATE_NEW_PASSWORD_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(createNewPasswordRequest());
    return userService.changePassword(body)
      .then(
        (token) => {
          dispatch(createNewPasswordSuccess(token));
        },
        (error) => {
          dispatch(createNewPasswordFailure(error.toString()));
        },
      )
      .catch((error) => {
        console.error(error);
      });
  };
}

function getUser(userId) {
  function request(user) { return { type: userConstants.GET_USER_REQUEST, user }; }
  function success(user) { return { type: userConstants.GET_USER_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.GET_USER_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(userId));
    return userService.getUserById(userId)
      .then(
        (user) => {
          dispatch(success(user));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

function createUser(body) {
  function request() { return { type: userConstants.CREATE_USER_REQUEST }; }
  function success(user) { return { type: userConstants.CREATE_USER_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.CREATE_USER_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    return userService.createUser(body)
      .then(
        (user) => {
          dispatch(alertActions.successWithToast('Utilisateur créé avec succès'));
          dispatch(success(user));
        },
        (error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

function updateUser(body) {
  function request() { return { type: userConstants.UPDATE_USER_REQUEST }; }
  function success(user) { return { type: userConstants.UPDATE_USER_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.UPDATE_USER_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    return services.putData(`user/${body.id}`, body)
      .then(
        (user) => {
          dispatch(alertActions.successWithToast('Utilisateur mis à jour avec succès'));
          dispatch(success(user));
        },
        (error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

function logout() {
  return (dispatch) => {
    dispatch({ type: userConstants.LOGOUT });
    userService.logout();
  };
}

const userActions = {
  login,
  requestNewPassword,
  checkPasswordToken,
  createNewPassword,
  logout,
  getUser,
  createUser,
  updateUser,
};

export default userActions;
