/* eslint-disable import/no-cycle */
import Cookies from 'js-cookie';
import axios from 'axios';

import authHeader from '../../helpers/authHeader';
import config from '../../config';
import handleResponse from './axios';

function login(email, password) {
  return axios.post(`${config.API_URL}/login`, { email, password })
    .then(handleResponse)
    .then((user) => user)
    .catch((error) => Promise.reject(error.response.data.message));
}

function requestNewPassword(email) {
  return axios.post(`${config.API_URL}/auth/forgot_password`, { email })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function checkPasswordToken(email, token) {
  return axios.get(`${config.API_URL}/auth/reset_password/${encodeURIComponent(email)}/${encodeURIComponent(token)}`)
    .then(handleResponse)
    .then((data) => data);
}

function changePassword(body) {
  return axios.post(`${config.API_URL}/auth/reset_password`, body)
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function createUser(body) {
  return axios.post(`${config.API_URL}/user`,
    body,
    {
      headers: {
        Authorization: authHeader(),
      },
    })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function logout() {
  localStorage.clear();
  sessionStorage.clear();
  Cookies.remove('auth');
  Cookies.remove('refresh');
}

const userService = {
  login,
  requestNewPassword,
  checkPasswordToken,
  changePassword,
  createUser,
  logout,
};

export default userService;
