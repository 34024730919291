const trailerConstants = {
  CREATE_TRAILER_REQUEST: 'TRAILERS_CREATE_TRAILER_REQUEST',
  CREATE_TRAILER_SUCCESS: 'TRAILERS_CREATE_TRAILER_SUCCESS',
  CREATE_TRAILER_FAILURE: 'TRAILERS_CREATE_TRAILER_FAILURE',

  UPDATE_TRAILER_REQUEST: 'TRAILERS_UPDATE_TRAILER_REQUEST',
  UPDATE_TRAILER_SUCCESS: 'TRAILERS_UPDATE_TRAILER_SUCCESS',
  UPDATE_TRAILER_FAILURE: 'TRAILERS_UPDATE_TRAILER_FAILURE',
};

export default trailerConstants;
