import protocoleConstants from '../constants/protocoleConstants';

const initialState = {
  protocoleCreated: false,
  creatingNewProtocole: false,
};

export default function protocole(state = initialState, action) {
  switch (action.type) {
    case protocoleConstants.CREATE_PROTOCOLE_REQUEST:
      return {
        ...state,
        creatingNewProtocole: true,
        protocoleCreated: false,
      };
    case protocoleConstants.CREATE_PROTOCOLE_SUCCESS:
      return {
        ...state,
        creatingNewProtocole: false,
        protocoleCreated: true,
        protocoleId: action.protocole.id,
      };
    case protocoleConstants.CREATE_PROTOCOLE_FAILURE:
      return {
        ...state,
        creatingNewProtocole: false,
        protocoleCreated: false,
      };
    default:
      return state;
  }
}
