import driverConstants from '../constants/driverConstants';

const initialState = {
  creatingNewDriver: false,
  driverCreated: false,
  driverUpdated: false,
};

export default function driver(state = initialState, action) {
  switch (action.type) {
    case driverConstants.CREATE_DRIVER_REQUEST:
      return {
        ...state,
        creatingNewDriver: true,
        driverCreated: false,
      };
    case driverConstants.CREATE_DRIVER_SUCCESS:
      return {
        ...state,
        creatingNewDriver: false,
        driverCreated: true,
      };
    case driverConstants.CREATE_DRIVER_FAILURE:
      return {
        ...state,
        creatingNewDriver: false,
        driverCreated: false,
      };
    case driverConstants.UPDATE_DRIVER_REQUEST:
      return {
        ...state,
        updatingNewDriver: true,
        driverUpdated: false,
      };
    case driverConstants.UPDATE_DRIVER_SUCCESS:
      return {
        ...state,
        updatingNewDriver: false,
        driverUpdated: true,
      };
    case driverConstants.UPDATE_DRIVER_FAILURE:
      return {
        ...state,
        updatingNewDriver: false,
        driverUpdated: false,
      };
    case driverConstants.UNLOAD_DRIVER:
      return {};
    default:
      return state;
  }
}
