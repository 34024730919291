const materialConstants = {
  CREATE_MATERIAL_REQUEST: 'MATERIALS_CREATE_MATERIAL_REQUEST',
  CREATE_MATERIAL_SUCCESS: 'MATERIALS_CREATE_MATERIAL_SUCCESS',
  CREATE_MATERIAL_FAILURE: 'MATERIALS_CREATE_MATERIAL_FAILURE',

  UPDATE_MATERIAL_REQUEST: 'MATERIALS_UPDATE_MATERIAL_REQUEST',
  UPDATE_MATERIAL_SUCCESS: 'MATERIALS_UPDATE_MATERIAL_SUCCESS',
  UPDATE_MATERIAL_FAILURE: 'MATERIALS_UPDATE_MATERIAL_FAILURE',
};

export default materialConstants;
