import store from '../redux/store';

export default function authHeader() {
  // return authorization header with jwt token
  const state = store.getState();
  let auth;
  if (state.auth) {
    auth = state.auth.data.token;
  }
  if (auth) {
    return `Bearer ${auth}`;
  }
  return {};
}
