import uiConstants from '../constants/uiConstants';
import alertActions from './alertActions';
import services from '../services/services';

function changePageTitle(title) {
  sessionStorage.setItem('currentPage', JSON.stringify(title));
  return { type: uiConstants.CHANGE_PAGE_TITLE, title };
}

function handleModal(modalData) {
  return { type: uiConstants.HANDLE_MODAL, modalData };
}

function importData(route, data) {
  function request() {
    return {
      type: uiConstants.IMPORT_DATA_REQUEST,
    };
  }

  function success() {
    return {
      type: uiConstants.IMPORT_DATA_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: uiConstants.IMPORT_DATA_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    return services.importData(route, data)
      .then(
        () => {
          dispatch(alertActions.successWithToast('Données importées avec succès'));
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error ? error.toString() : 'error'));
          dispatch(alertActions.error(error ? error.toString() : 'error'));
        },
      );
  };
}

const uiActions = {
  changePageTitle,
  handleModal,
  importData,
};

export default uiActions;
