import { toastr } from 'react-redux-toastr';
import alertConstants from '../constants/alertConstants';

function successWithToast(message) {
  toastr.success('Success', message);
  return { type: alertConstants.SUCCESS, message };
}

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  toastr.error('Error', message);
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

const alertActions = {
  success,
  successWithToast,
  error,
  clear,
};

export default alertActions;
