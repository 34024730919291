const dev = {
  API_URL: "http://localhost:8000",
};

const prod = {
  API_URL: "https://basfelbeuf.olras.com/api",
  PUBLIC_URL: "https://basfelbeuf.olras.com/",
};

const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;
