/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import TablePageLoader from './Loader/TablePageLoader';
import LoginLoader from './Loader/LoginLoader';

import Layout from '../Layout/index';

const TableLoading = ({ error, retry, pastDelay }) => {
  if (error) {
    return (
      <div>
        Error!
        <button type="button" onClick={retry}>Retry</button>
      </div>
    );
  } if (pastDelay) {
    return (
      <div style={{ width: '100%' }}>
        <TablePageLoader style={{ width: '100%' }} />
      </div>
    );
  }
  return null;
};

const LoginLoading = ({ error, retry, pastDelay }) => {
  if (error) {
    return (
      <div>
        Error!
        <button type="button" onClick={retry}>Retry</button>
      </div>
    );
  } if (pastDelay) {
    return (
      <div style={{ width: '100%' }}>
        <LoginLoader style={{ width: '100%' }} />
      </div>
    );
  }
  return null;
};

const Login = Loadable({
  loader: () => import('../Login/index'),
  loading: LoginLoading,
});

const Drivers = Loadable({
  loader: () => import('../Drivers/index'),
  loading: TableLoading,
});

const Tractors = Loadable({
  loader: () => import('../Tractors/index'),
  loading: TableLoading,
});

const Trailers = Loadable({
  loader: () => import('../Trailers/index'),
  loading: TableLoading,
});

const TrailerTypes = Loadable({
  loader: () => import('../TrailerTypes/index'),
  loading: TableLoading,
});

const Materials = Loadable({
  loader: () => import('../Materials/index'),
  loading: TableLoading,
});

const Users = Loadable({
  loader: () => import('../Users/index'),
  loading: TableLoading,
});

const NewProtocole = Loadable({
  loader: () => import('../Protocoles/NewProtocole/index'),
  loading: TableLoading,
});

const InProgressProtocole = Loadable({
  loader: () => import('../Protocoles/InProgressProtocole/index'),
  loading: TableLoading,
});

const closedProtocole = Loadable({
  loader: () => import('../Protocoles/closedProtocole/index'),
  loading: TableLoading,
});

const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/new_protocole',
  allowRedirectBack: false,
  authenticatedSelector: (state) => state.auth.loggedIn !== true,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => state.auth.loggedIn === true,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const WrappedRoutes = () => (
  <div>
    <Layout>
      <Switch>
        <Route path="/drivers/" component={Drivers} />
        <Route path="/tractors/" component={Tractors} />
        <Route path="/trailers/" component={Trailers} />
        <Route path="/trailer_types/" component={TrailerTypes} />
        <Route path="/materials/" component={Materials} />
        <Route path="/users/" component={Users} />
        <Route path="/new_protocole/" component={NewProtocole} />
        <Route path="/in_progress_protocole/" component={InProgressProtocole} />
        <Route path="/closed_protocole/" component={closedProtocole} />
      </Switch>
    </Layout>
  </div>
);

TableLoading.defaultProps = {
  error: false,
  retry: null,
};

TableLoading.propTypes = {
  error: PropTypes.any,
  retry: PropTypes.any,
  pastDelay: PropTypes.any.isRequired,
};

LoginLoading.defaultProps = {
  error: false,
  retry: null,
};

LoginLoading.propTypes = {
  error: PropTypes.any,
  retry: PropTypes.any,
  pastDelay: PropTypes.any.isRequired,
};

const Router = () => (
  <Switch>
    <Route exact path="/" component={userIsNotAuthenticated(Login)} />
    <Route path="/" component={userIsAuthenticated(WrappedRoutes)} />
  </Switch>
);

export default Router;
