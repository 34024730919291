import axios from 'axios';

import config from '../../config';
import handleResponse from './axios';
import authHeader from '../../helpers/authHeader';

const FileDownload = require('js-file-download');

function getDataTable(url) {
  return axios.get(`${config.API_URL}/${url}`,
    {
      headers: {
        Authorization: authHeader(),
      },
    })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function createData(url, body) {
  return axios.post(`${config.API_URL}/${url}`,
    body,
    {
      headers: {
        Authorization: authHeader(),
      },
    })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function putData(url, body) {
  return axios.put(`${config.API_URL}/${url}`,
    body,
    {
      headers: {
        Authorization: authHeader(),
      },
    })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function deleteData(url) {
  return axios.delete(`${config.API_URL}/${url}`,
    {
      headers: {
        Authorization: authHeader(),
      },
    })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function exportData(route) {
  return axios.get(`${config.API_URL}/${route}/export/csv`,
    {
      headers: {
        Authorization: authHeader(),
      },
      responseType: 'blob',
    })
    .then((res) => FileDownload(res.data, `${route}.xlsx`))
    .catch((error) => Promise.reject(error.response.data.message));
}

function importData(url, body) {
  return axios({
    method: 'post',
    url: `${config.API_URL}/${url}`,
    data: body,
    headers: {
      Authorization: authHeader(),
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.detail.errors[0].message));
}

function restoreData(url) {
  return axios.put(`${config.API_URL}/${url}`, {
    headers: {
      Authorization: authHeader(),
    },
  })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

const services = {
  createData,
  getDataTable,
  putData,
  deleteData,
  exportData,
  importData,
  restoreData,
};

export default services;
