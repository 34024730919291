import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { reducer as toastrReducer } from 'react-redux-toastr';
import {
  userReducer,
  authenticationReducer,
  uiReducer,
  protocoleReducer,
  driverReducer,
  materialReducer,
  tractorReducer,
  trailerReducer,
  trailerTypeReducer,
} from './reducers/index';

let middlewares;

if (process.env.NODE_ENV === 'development') {
  middlewares = [thunk, logger];
} else {
  middlewares = [thunk];
}

const appReducer = combineReducers({
  form: reduxFormReducer,
  toastr: toastrReducer,
  user: userReducer,
  auth: authenticationReducer,
  ui: uiReducer,
  protocole: protocoleReducer,
  driver: driverReducer,
  material: materialReducer,
  tractor: tractorReducer,
  trailer: trailerReducer,
  trailerType: trailerTypeReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  let newState = state;
  if (action.type === 'USER_LOGGED_OUT') {
    newState = undefined;
  }

  return appReducer(newState, action);
};

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
