import Cookies from 'js-cookie';
import userConstants from '../constants/userConstants';

const auth = Cookies.get('auth');
const user = JSON.parse(localStorage.getItem('user'));

let initialState = {
  loggedIn: false,
};

if (auth) {
  initialState = {
    loggedIn: true,
    data: {
      token: auth,
      user,
    },
  };
} else {
  initialState = {
    loggedIn: false,
  };
}

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        data: action.auth.data,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
      };
    case userConstants.LOGOUT:
      return {
        loggedIn: false,
      };
    default:
      return state;
  }
}
