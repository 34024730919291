import materialConstants from '../constants/materialConstants';

const initialState = {
  creatingNewMaterial: false,
  materialCreated: false,
  updatingNewMaterial: false,
  materialUpdated: false,
};

export default function material(state = initialState, action) {
  switch (action.type) {
    case materialConstants.CREATE_MATERIAL_REQUEST:
      return {
        ...state,
        creatingNewMaterial: true,
        materialCreated: false,
      };
    case materialConstants.CREATE_MATERIAL_SUCCESS:
      return {
        ...state,
        creatingNewMaterial: false,
        materialCreated: true,
      };
    case materialConstants.CREATE_MATERIAL_FAILURE:
      return {
        ...state,
        creatingNewMaterial: false,
        materialCreated: false,
      };
    case materialConstants.UPDATE_MATERIAL_REQUEST:
      return {
        ...state,
        updatingNewMaterial: true,
        materialUpdated: false,
      };
    case materialConstants.UPDATE_MATERIAL_SUCCESS:
      return {
        ...state,
        updatingNewMaterial: false,
        materialUpdated: true,
      };
    case materialConstants.UPDATE_MATERIAL_FAILURE:
      return {
        ...state,
        updatingNewMaterial: false,
        materialUpdated: false,
      };
    case materialConstants.UNLOAD_MATERIAL:
      return {};
    default:
      return state;
  }
}
