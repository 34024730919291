import trailerConstants from '../constants/trailerConstants';

const initialState = {
  creatingNewTrailer: false,
  trailerCreated: false,
  updatingNewTrailer: false,
  trailerUpdated: false,
};

export default function trailer(state = initialState, action) {
  switch (action.type) {
    case trailerConstants.CREATE_TRAILER_REQUEST:
      return {
        ...state,
        creatingNewTrailer: true,
        trailerCreated: false,
      };
    case trailerConstants.CREATE_TRAILER_SUCCESS:
      return {
        ...state,
        creatingNewTrailer: false,
        trailerCreated: true,
      };
    case trailerConstants.CREATE_TRAILER_FAILURE:
      return {
        ...state,
        creatingNewTrailer: false,
        trailerCreated: false,
      };
    case trailerConstants.UPDATE_TRAILER_REQUEST:
      return {
        ...state,
        updatingNewTrailer: true,
        trailerUpdated: false,
      };
    case trailerConstants.UPDATE_TRAILER_SUCCESS:
      return {
        ...state,
        updatingNewTrailer: false,
        trailerUpdated: true,
      };
    case trailerConstants.UPDATE_TRAILER_FAILURE:
      return {
        ...state,
        updatingNewTrailer: false,
        trailerUpdated: false,
      };
    case trailerConstants.UNLOAD_TRAILER:
      return {};
    default:
      return state;
  }
}
