import React from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReduxToastr from 'react-redux-toastr';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CacheBuster from '../../cacheBuster';
import Router from './Router';
import store from '../../redux/store';

import 'fontsource-roboto';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () => createMuiTheme({
      overrides: {
        MuiAccordionSummary: {
          root: {
            backgroundColor: '#bda369',
            color: '#fff',
            borderRadius: '4px 4px 0 0',
          },
          expandIcon: {
            color: '#fff',
          },
        },
      },
      palette: {
        primary: {
          main: '#444492',
        },
        secondary: {
          main: '#bda369',
          contrastText: '#fff',
        },
      },
    }),
    [prefersDarkMode],
  );
  return (
    <>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <Provider store={store}>
              <CssBaseline />
              <ThemeProvider theme={theme}>
                <ReduxToastr
                  timeOut={4000}
                  newestOnTop={false}
                  preventDuplicates
                  position="top-right"
                  getState={(state) => state.toastr} // This is the default
                  transitionIn="fadeIn"
                  transitionOut="fadeOut"
                  progressBar
                  closeOnToastrClick
                />
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </ThemeProvider>
            </Provider>
          );
        }}
      </CacheBuster>
    </>
  );
};

export default hot(module)(App);
