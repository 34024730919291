import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Typography from '@material-ui/core/Typography';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PeopleIcon from '@material-ui/icons/People';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import TextureIcon from '@material-ui/icons/Texture';

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    overflow: 'auto',
  },
  drawerHeader: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    paddingLeft: '1em',
    ...theme.mixins.toolbar,
  },
  menuTitle: {
    fontSize: '1.25rem',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: 1.6,
    color: '#0000008a',
  },
  menuSubtitle: {
    fontSize: '0.75rem',
    fontFamily: 'Roboto',
    lineHeight: 1.6,
    color: '#0000008a',
  },
  menuCategories: {
    fontSize: '1.25rem',
    fontFamily: 'Roboto',
    lineHeight: 1.6,
    color: '#0000008a',
  },
}));

const Menu = ({
  entity, handleDrawerClose, accountType,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.drawerContainer}>
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
        <Grid container>
          <Grid item md={12}>
            <Typography variant="h6" className={classes.menuTitle}>
              Menu
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="h6" className={classes.menuSubtitle}>
              {accountType === 'REPORTING' ? 'REPORTING' : entity }
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={classes.drawerHeader}>
        <Typography variant="h6" className={classes.menuCategories}>
          Protocoles
        </Typography>
      </div>
      <List>
        { (accountType === 'PEN' || accountType === 'ADMIN') && (
          <>
            <ListItem
              component={Link}
              to="/new_protocole"
              button
            >
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Nouveau protocole" />
            </ListItem>
            <ListItem
              component={Link}
              to="/in_progress_protocole"
              button
            >
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Protocoles en cours" />
            </ListItem>
          </>
        )}
        <ListItem
          component={Link}
          to="/closed_protocole"
          button
        >
          <ListItemIcon>
            <FormatListBulletedIcon />
          </ListItemIcon>
          <ListItemText primary="Archivage Protocoles" />
        </ListItem>
      </List>
      <Divider />
      <div className={classes.drawerHeader}>
        <Typography variant="h6" className={classes.menuCategories}>
          Données
        </Typography>
      </div>
      <List>
        <ListItem
          component={Link}
          to="/drivers"
          button
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Conducteurs" />
        </ListItem>
        <ListItem
          component={Link}
          to="/tractors"
          button
        >
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText primary="Tracteurs" />
        </ListItem>
        <ListItem
          component={Link}
          to="/trailers"
          button
        >
          <ListItemIcon>
            <LocalShippingTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="Remorques" />
        </ListItem>
        <ListItem
          component={Link}
          to="/trailer_types"
          button
        >
          <ListItemIcon>
            <LocalShippingTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="Types remorques" />
        </ListItem>
      </List>
      { (accountType === 'ADMIN' || accountType === 'REPORTING') && (
      <>
        <Divider />
        <div className={classes.drawerHeader}>
          <Typography variant="h6" className={classes.menuCategories}>
            Admin
          </Typography>
        </div>
        <List>
          <ListItem
            component={Link}
            to="/materials"
            button
          >
            <ListItemIcon>
              <TextureIcon />
            </ListItemIcon>
            <ListItemText primary="Matières" />
          </ListItem>
          <ListItem
            component={Link}
            to="/users"
            button
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Utilisateurs" />
          </ListItem>
        </List>
      </>
      )}
    </div>
  );
};

Menu.propTypes = {
  entity: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

export default Menu;
