import trailerTypeConstants from '../constants/trailerTypeConstants';

const initialState = {
  creatingNewTrailerType: false,
  trailerTypeCreated: false,
  updatingNewTrailerType: false,
  trailerTypeUpdated: false,
};

export default function trailerType(state = initialState, action) {
  switch (action.type) {
    case trailerTypeConstants.CREATE_TRAILER_TYPE_REQUEST:
      return {
        ...state,
        creatingNewTrailerType: true,
        trailerTypeCreated: false,
      };
    case trailerTypeConstants.CREATE_TRAILER_TYPE_SUCCESS:
      return {
        ...state,
        creatingNewTrailerType: false,
        trailerTypeCreated: true,
      };
    case trailerTypeConstants.CREATE_TRAILER_TYPE_FAILURE:
      return {
        ...state,
        creatingNewTrailerType: false,
        trailerTypeCreated: false,
      };
    case trailerTypeConstants.UPDATE_TRAILER_TYPE_REQUEST:
      return {
        ...state,
        updatingNewTrailerType: true,
        trailerTypeUpdated: false,
      };
    case trailerTypeConstants.UPDATE_TRAILER_TYPE_SUCCESS:
      return {
        ...state,
        updatingNewTrailerType: false,
        trailerTypeUpdated: true,
      };
    case trailerTypeConstants.UPDATE_TRAILER_TYPE_FAILURE:
      return {
        ...state,
        updatingNewTrailerType: false,
        trailerTypeUpdated: false,
      };
    case trailerTypeConstants.UNLOAD_TRAILER_TYPE:
      return {};
    default:
      return state;
  }
}
