/* eslint-disable import/no-cycle */
import userService from './userServices';

export default function handleResponse(response) {
  const { data } = response;
  if (!response.statusText === 'OK') {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      userService.logout();
    }
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  return data;
}
